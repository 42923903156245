import {PageContainer} from '@hconnect/uikit/src/lib2'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate, useParams} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {ChecklistPageHeader} from '../components/ChecklistPageHeader'
import {ChecklistContainer} from '../containers/ChecklistContainer'
import {PLANT_CHECKLISTS_TEMPLATES} from '../routing'

type PathParameter = {
  plantId?: string
  checklistId?: string
}

export const PlantChecklistPage = () => {
  const {plantId, checklistId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')
  if (!checklistId) throw new Error('checklistId is missing')

  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()
  const navigate = useNavigate()

  return (
    <PageContainer>
      <ChecklistPageHeader
        title={t(`${checklistsPrefix}.takeFive.pageTitle`)}
        backButtonProps={{
          target: generatePath(PLANT_CHECKLISTS_TEMPLATES, {plantId}),
          targetName: t(`${checklistsPrefix}.action.backToChecklistDetails`)
        }}
      />
      <ChecklistContainer
        checklistId={checklistId}
        plantId={plantId}
        onCancel={() => {
          navigate(generatePath(PLANT_CHECKLISTS_TEMPLATES, {plantId}))
        }}
      />
    </PageContainer>
  )
}
