import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from '@mui/icons-material'
import {
  Checkbox,
  CircularProgress,
  MenuItem,
  Autocomplete as MuiAutocomplete,
  Typography,
  type AutocompleteProps as MuiAutocompleteProps
} from '@mui/material'

import {BaseTextField} from '../../components/inputs/BaseTextField'

export type AutocompleteProps<TOption extends {id: string}> = Omit<
  MuiAutocompleteProps<TOption, boolean, boolean, false>,
  'renderInput'
> & {
  name: string
  label: string
  helperText?: string
  error?: boolean
}

export const Autocomplete = <TOption extends {id: string}>({
  name,
  options,
  label,
  disabled,
  readOnly,
  error,
  helperText,
  disableClearable = true,
  placeholder,
  loading,
  multiple,
  ...otherProps
}: AutocompleteProps<TOption>) => (
  <MuiAutocomplete
    {...otherProps}
    readOnly={readOnly}
    disabled={readOnly ? false : disabled}
    ChipProps={{
      sx: {
        borderRadius: 1,
        marginRight: 1
      },
      variant: 'outlined',
      size: 'small'
    }}
    componentsProps={{
      paper: {
        elevation: 8
      }
    }}
    loading={loading}
    renderInput={(params) => (
      <BaseTextField
        {...params}
        placeholder={placeholder}
        InputProps={{
          ...params.InputProps,
          readOnly,
          endAdornment: readOnly ? undefined : (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          )
        }}
        label={label}
        error={error}
        helperText={helperText}
        data-test-id={`autocomplete-text-field-${name}`}
      />
    )}
    options={options}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    disableClearable={disableClearable}
    multiple={multiple}
    {...(multiple
      ? {
          renderOption: renderOption as AutocompleteProps<TOption>['renderOption'],
          disableCloseOnSelect: otherProps.disableCloseOnSelect ?? true
        }
      : {})}
  />
)

const renderOption = <TOption extends {id: string}>(
  props: React.HTMLAttributes<HTMLLIElement>,
  option: TOption,
  {selected}: {selected: boolean},
  {getOptionLabel}: {getOptionLabel: (option: TOption) => string}
) => (
  <MenuItem {...props}>
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" />}
      style={{marginRight: 8}}
      checked={selected}
    />
    <Typography variant="subtitle1">{getOptionLabel(option)}</Typography>
  </MenuItem>
)
