import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../../shared/hooks/useTranslationPrefix'
import {ChecklistStepFormFields, HazardAssesmentFormFields} from '../../containers/TakeFive'
import {
  YesNoFormValue,
  type TakeFiveHazardAssesmentStepFormValues,
  type TakeFiveSafetyStepFormValues
} from '../../types'
import {
  getSafetyValidationSchema,
  getHazardAssesmentValidationSchema
} from '../../validators/take-five.validators'
import {useGlobalContext} from '../useGlobalContext'
import {type StepsConfig} from '../useStepperFormContext'

export const useTakeFiveStepsConfig = (): StepsConfig => {
  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()
  const {user} = useGlobalContext()

  return useMemo(
    () => [
      {
        key: 'safety',
        label: t(`${checklistsPrefix}.takeFive.label.checklist`),
        StepFormContent: ChecklistStepFormFields,
        validationSchema: getSafetyValidationSchema({t}),
        defaultValues: safetyDefaultValues
      },
      {
        key: 'hazardAssesment',
        label: t(`${checklistsPrefix}.takeFive.label.hazardAssesment`),
        StepFormContent: HazardAssesmentFormFields,
        validationSchema: getHazardAssesmentValidationSchema({t}),
        defaultValues: {...hazardAssesmentDefaultValues, preparedBy: user.name}
      }
    ],
    [t, checklistsPrefix, user]
  )
}

const safetyDefaultValues: TakeFiveSafetyStepFormValues = {
  peopleInformed: {value: YesNoFormValue.YES, comment: ''},
  rightTools: {value: YesNoFormValue.YES, comment: ''},
  fitForDuty: {value: YesNoFormValue.YES, comment: ''},
  riskRecognized: {value: YesNoFormValue.YES, comment: ''},
  reasonToWorkSafe: '',
  supervisor: null
}

const hazardAssesmentDefaultValues: TakeFiveHazardAssesmentStepFormValues = {
  preparedBy: null,
  workToBeDone: '',
  hazards: [],
  hazardsComment: '',
  risks: [],
  risksComment: '',
  eliminateControlHazard: ''
}
