import {CardBox} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/material'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useTranslationPrefix} from '../../../shared/hooks/useTranslationPrefix'
import {ChecklistStepSection} from '../../components/ChecklistStepSection'
import {TextField, UserSearchAutocompleteField, YesNoWithCommentField} from '../../components/form'
import type {TakeFiveSafetyStepFormValues} from '../../types'
import {StepFormActionsContainer} from '../StepFormActionsContainer'

type PathParameters = {
  plantId: string
}

type Props = {
  readOnly?: boolean
}

export const ChecklistStepFormFields = ({readOnly = false}: Props) => {
  const {control} = useFormContext<TakeFiveSafetyStepFormValues>()
  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()
  const {plantId} = useParams<PathParameters>()
  if (!plantId) {
    throw new Error('PlantId is required')
  }

  return (
    <CardBox width={{xs: '100%', md: '632px'}}>
      <Stack spacing={2}>
        <ChecklistStepSection title={t(`${checklistsPrefix}.takeFive.label.safetyChecklist`)}>
          <YesNoWithCommentField
            label={t(`${checklistsPrefix}.takeFive.label.peopleInformed`)}
            name="peopleInformed"
            control={control}
            readOnly={readOnly}
          />
          <YesNoWithCommentField
            label={t(`${checklistsPrefix}.takeFive.label.rightTools`)}
            name="rightTools"
            control={control}
            readOnly={readOnly}
          />
          <YesNoWithCommentField
            label={t(`${checklistsPrefix}.takeFive.label.fitForDuty`)}
            name="fitForDuty"
            control={control}
            readOnly={readOnly}
          />
          <YesNoWithCommentField
            label={t(`${checklistsPrefix}.takeFive.label.riskRecognized`)}
            name="riskRecognized"
            control={control}
            readOnly={readOnly}
          />
        </ChecklistStepSection>
        <ChecklistStepSection title={t(`${checklistsPrefix}.takeFive.label.reasonToWorkSafe`)}>
          <TextField
            name="reasonToWorkSafe"
            label={t(`${checklistsPrefix}.label.yourAnswer`)}
            optional
            control={control}
            readOnly={readOnly}
          />
        </ChecklistStepSection>
        <ChecklistStepSection title={t(`${checklistsPrefix}.takeFive.label.supervisor`)}>
          <UserSearchAutocompleteField
            plantId={plantId}
            name="supervisor"
            label={t(`${checklistsPrefix}.takeFive.label.supervisor`)}
            control={control}
            readOnly={readOnly}
          />
        </ChecklistStepSection>
        <StepFormActionsContainer />
      </Stack>
    </CardBox>
  )
}
