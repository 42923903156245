export enum ChecklistTemplateType {
  HealthAndSafety = 'healthAndSafety',
  ProcessWorkOrders = 'processWorkOrders',
  POM = 'pom'
}

export enum ChecklistTemplateCategory {
  None = 'none',
  PreAssesment = 'preAssesment'
}

export enum ChecklistTemplateKind {
  TakeFive = 'take5'
}

export type ChecklistTemplateDto = {
  id: string
  name: string
  description: string
  type: ChecklistTemplateType
  category: ChecklistTemplateCategory
  kind: ChecklistTemplateKind
  createdAt: string
}

export type ChecklistTemplate = Pick<
  ChecklistTemplateDto,
  'id' | 'name' | 'description' | 'type' | 'category' | 'createdAt'
>
