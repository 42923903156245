import {type TFunction} from 'i18next'

import {CHECKLISTS_PREFIX} from '../../shared/consts'
import {
  ChecklistTemplateKind,
  YesNoFormValue,
  type ChecklistDto,
  type ChecklistFormValues,
  type TakeFiveChecklistData,
  type TakeFiveChecklistDto,
  type TakeFiveChecklistFormValues,
  type YesNoItemWithComment,
  type YesNoItemWithCommentFormValues
} from '../types'

export const mapChecklistDtoToChecklistFormValues = (
  checklist: ChecklistDto,
  t: TFunction
): ChecklistFormValues => {
  switch (checklist.checklistTemplateKind) {
    case ChecklistTemplateKind.TakeFive:
      return mapTakeFiveChecklistDtoToFormValues(checklist, t)
    default:
      throw new Error(`Unknown checklist template kind: ${checklist.checklistTemplateKind}`)
  }
}

export const mapChecklistFormValuesToChecklistDtoData = (
  formValues: ChecklistFormValues,
  templateKind: ChecklistTemplateKind
): ChecklistDto['data'] => {
  switch (templateKind) {
    case ChecklistTemplateKind.TakeFive:
      return mapTakeFiveChecklistFormValuesToDataDto(formValues)
    default:
      throw new Error(`Unknown checklist template kind: ${formValues.checklistTemplateKind}`)
  }
}

const mapYesNoItemToFormItem = (item: YesNoItemWithComment): YesNoItemWithCommentFormValues => ({
  value: item.value ? YesNoFormValue.YES : YesNoFormValue.NO,
  comment: item.comment
})

const mapYesNoFormValuesToItem = (item: YesNoItemWithCommentFormValues): YesNoItemWithComment => ({
  value: item.value === YesNoFormValue.YES,
  comment: item.comment
})

export const mapTakeFiveChecklistDtoToFormValues = (
  {data: {safety, hazardAssesment}, checklistTemplateKind, preparedBy}: TakeFiveChecklistDto,
  t: TFunction
): TakeFiveChecklistFormValues => {
  return {
    checklistTemplateKind,
    safety: safety
      ? {
          ...safety,
          peopleInformed: mapYesNoItemToFormItem(safety.peopleInformed),
          rightTools: mapYesNoItemToFormItem(safety.rightTools),
          fitForDuty: mapYesNoItemToFormItem(safety.fitForDuty),
          riskRecognized: mapYesNoItemToFormItem(safety.riskRecognized)
        }
      : null,
    hazardAssesment: hazardAssesment
      ? {
          ...hazardAssesment,
          hazards: hazardAssesment.hazards.map((hazard) => ({
            id: hazard,
            label: t(`${CHECKLISTS_PREFIX}.takeFive.hazard.${hazard}`)
          })),
          risks: hazardAssesment.risks.map((risk) => ({
            id: risk,
            label: t(`${CHECKLISTS_PREFIX}.takeFive.risk.${risk}`)
          })),
          preparedBy: preparedBy ? preparedBy.name : null
        }
      : null
  }
}

export const mapTakeFiveChecklistFormValuesToDataDto = ({
  safety,
  hazardAssesment
}: TakeFiveChecklistFormValues): TakeFiveChecklistData => {
  return {
    safety: safety
      ? {
          ...safety,
          peopleInformed: mapYesNoFormValuesToItem(safety.peopleInformed),
          rightTools: mapYesNoFormValuesToItem(safety.rightTools),
          fitForDuty: mapYesNoFormValuesToItem(safety.fitForDuty),
          riskRecognized: mapYesNoFormValuesToItem(safety.riskRecognized)
        }
      : null,
    hazardAssesment: hazardAssesment
      ? {
          ...hazardAssesment,
          hazards: hazardAssesment.hazards.map((hazard) => hazard.id),
          risks: hazardAssesment.risks.map((risk) => risk.id)
        }
      : null
  }
}
