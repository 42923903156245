import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {ChecklistDto} from '../../types'

export const ChecklistsQueryKey = 'checklists'
const STALE_TIME_IN_MS = 1000 * 60 * 15

type ChecklistParams = {
  id: string
  plantId: string
}

export const useChecklist = (
  {id, plantId}: ChecklistParams,
  options?: UseQueryOptions<ChecklistDto, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<ChecklistDto, AxiosError>(
    [ChecklistsQueryKey, plantId, id],
    async () => {
      const response = await axiosInstance.get<ChecklistDto>(
        `/hroc-checklists/api/${plantId}/checklists/${id}`
      )

      return response.data
    },
    {
      staleTime: STALE_TIME_IN_MS,
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
