import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

export const useGetLabelWithSuffix = ({label, optional}: {label: string; optional?: boolean}) => {
  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()

  let suffix = ''
  if (optional) suffix = ` (${t(`${checklistsPrefix}.label.optional`)})`

  return label + suffix
}
