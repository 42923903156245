import {Permission} from '@hconnect/apiclient'
import React from 'react'

import {type Context} from '../../shared/types/temp.types'

type UseGlobalContextReturn = {
  user: Context['user']
  permissions: Permission[]
}

type GlobalContextProviderProps = {
  initialState: {
    user: Context['user']
    permissions: Permission[]
  }
  children?: React.ReactNode
}

const context = React.createContext<UseGlobalContextReturn | undefined>(undefined)

export const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({
  initialState,
  children
}) => {
  return <context.Provider value={initialState}>{children}</context.Provider>
}

export function useGlobalContext(): UseGlobalContextReturn {
  const ctx = React.useContext(context)
  if (ctx === undefined) {
    throw new Error('useGlobalContext was used outside the scope of a GlobalContextProvider!')
  } else {
    return ctx
  }
}
