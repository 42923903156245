import type {User} from '@hconnect/common/types'
import {type AxiosError} from 'axios'
import {useQuery, type UseQueryOptions} from 'react-query'

import {useAxios} from '../../../shared/hooks/useApi'

const QueryKey = 'user-search'
const CACHE_TIME_IN_MS = 1000 * 60

export const useUserSearch = (
  plantId: string,
  namePattern: string | undefined,
  options?: UseQueryOptions<User[], AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<User[], AxiosError>(
    [QueryKey, namePattern, plantId],
    async () => {
      // TODO: this is interim sulution until hroc-checklists user search endpoint will be available
      // will be replaced in task: HCP-77260
      const path = `/shifthandover/${plantId}/search/taskassignee?namePattern=${namePattern}`

      if (!namePattern) {
        return Promise.resolve([])
      }
      const response = await axiosInstance.post<User[]>(path)
      return response.data
    },
    {
      cacheTime: CACHE_TIME_IN_MS,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
