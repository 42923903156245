import type {User} from '@hconnect/common/types'
import {isString} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../../shared/hooks/useTranslationPrefix'
import {useUserSearch} from '../../hooks/api'

import {Autocomplete, type AutocompleteProps} from './Autocomplete'

export type UserSearchAutocompleteProps = Omit<AutocompleteProps<User>, 'options'> & {
  plantId: string
}

const getOptionLabel = (option: User | string) => (isString(option) ? option : option?.name)

export const UserSearchAutocomplete = ({
  value,
  plantId,
  ...otherProps
}: UserSearchAutocompleteProps) => {
  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()
  const [open, setOpen] = React.useState(false)
  const [searchTerm, setSelectedSearchTerm] = React.useState<string | undefined>(undefined)

  // to speed up the search we fetch a list of users that include the first 3 Characters of the user input
  // and let the Autocomplete component filter the rest
  const choppedSearchTerm = searchTerm && searchTerm.length >= 3 ? searchTerm.slice(0, 3) : ''

  const {data, isFetching} = useUserSearch(plantId, choppedSearchTerm)

  const suggestions = useMemo(() => {
    if (Array.isArray(value)) {
      return ((data as User[]) ?? []).filter((user) => !value.find(({id}) => id === user.id))
    } else {
      return ((data as User[]) ?? []).filter((user) => value?.id !== user.id)
    }
  }, [data, value])

  return (
    <Autocomplete
      {...otherProps}
      value={value}
      onInputChange={(_, newValue) => {
        if (searchTerm !== newValue) {
          setSelectedSearchTerm(newValue)
        }
      }}
      open={open && suggestions.length >= 0}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={suggestions}
      filterSelectedOptions
      getOptionLabel={getOptionLabel}
      placeholder={t(`${checklistsPrefix}.label.search`)}
      loading={isFetching}
    />
  )
}
