import {CardBox} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/material'
import {useMemo} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../../shared/hooks/useTranslationPrefix'
import {ChecklistStepSection} from '../../components/ChecklistStepSection'
import {AutocompleteField, TextField} from '../../components/form'
import {Hazard, Risk, TakeFiveHazardAssesmentStepFormValues} from '../../types/checklist.types'
import {StepFormActionsContainer} from '../StepFormActionsContainer'

type Props = {
  readOnly?: boolean
}

export const HazardAssesmentFormFields = ({readOnly = false}: Props) => {
  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()
  const {control, watch} = useFormContext<TakeFiveHazardAssesmentStepFormValues>()
  const hazards = watch('hazards')
  const isHazardsCommentRequired = !!hazards?.find(({id}) => id === Hazard.Other)
  const risks = watch('risks')
  const isRisksCommentRequired = !!risks?.find(({id}) => id === Risk.Other)

  const hazardOptions = useMemo(
    () =>
      Object.values(Hazard).map((value) => ({
        id: value,
        label: t(`${checklistsPrefix}.takeFive.hazard.${value}`)
      })),
    [t, checklistsPrefix]
  )

  const riskOptions = useMemo(
    () =>
      Object.values(Risk).map((value) => ({
        id: value,
        label: t(`${checklistsPrefix}.takeFive.risk.${value}`)
      })),
    [t, checklistsPrefix]
  )

  return (
    <CardBox width={{xs: '100%', md: '632px'}}>
      <Stack spacing={2}>
        <ChecklistStepSection title={t(`${checklistsPrefix}.takeFive.label.hazardAssesment`)}>
          <TextField
            label={t(`${checklistsPrefix}.takeFive.label.preparedBy`)}
            name="preparedBy"
            readOnly // preparedBy is always read only
            control={control}
          />
        </ChecklistStepSection>
        <ChecklistStepSection
          title={t(`${checklistsPrefix}.takeFive.label.thinkThroughTheTask`)}
          subTitle={t(`${checklistsPrefix}.takeFive.label.whatWorkIsToBeDone`)}
        >
          <TextField
            label={t(`${checklistsPrefix}.label.yourAnswer`)}
            name="workToBeDone"
            readOnly={readOnly}
            control={control}
          />
        </ChecklistStepSection>
        <ChecklistStepSection
          title={t(`${checklistsPrefix}.takeFive.label.assessTheHazards`)}
          subTitle={t(`${checklistsPrefix}.takeFive.label.useHazardChecklist`)}
        >
          <AutocompleteField
            label={t(`${checklistsPrefix}.takeFive.label.hazards`)}
            name="hazards"
            control={control}
            multiple
            readOnly={readOnly}
            options={hazardOptions}
          />
          <TextField
            label={t(`${checklistsPrefix}.label.comment`)}
            name="hazardsComment"
            readOnly={readOnly}
            control={control}
            optional={!isHazardsCommentRequired}
          />
        </ChecklistStepSection>
        <ChecklistStepSection
          title={t(`${checklistsPrefix}.takeFive.label.knowTheRisk`)}
          subTitle={t(`${checklistsPrefix}.takeFive.label.whatArePotentialConsequences`)}
        >
          <AutocompleteField
            label={t(`${checklistsPrefix}.takeFive.label.risks`)}
            name="risks"
            control={control}
            multiple
            readOnly={readOnly}
            options={riskOptions}
          />
          <TextField
            label={t(`${checklistsPrefix}.label.comment`)}
            name="risksComment"
            readOnly={readOnly}
            control={control}
            optional={!isRisksCommentRequired}
          />
        </ChecklistStepSection>
        <ChecklistStepSection
          title={t(`${checklistsPrefix}.takeFive.label.eliminateOrControlTheHazard`)}
          subTitle={t(`${checklistsPrefix}.takeFive.label.howCanIEliminateOrReduceRisk`)}
        >
          <TextField
            label={t(`${checklistsPrefix}.label.yourAnswer`)}
            name="eliminateControlHazard"
            readOnly={readOnly}
            control={control}
          />
        </ChecklistStepSection>
        <StepFormActionsContainer />
      </Stack>
    </CardBox>
  )
}
