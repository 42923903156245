import {dateFormatter} from '@hconnect/uikit'
import {Column, DataTable} from '@hconnect/uikit/src/lib2'
import {TFunction} from 'i18next'
import moment from 'moment'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import type {ChecklistTemplate} from '../types'

type Props = {
  data?: ChecklistTemplate[]
  isLoading?: boolean
  emptyMessage?: string
}

export const ChecklistTemplatesTable = ({data, isLoading, emptyMessage}: Props) => {
  const {checklistsPrefix} = useTranslationPrefix()
  const {t} = useTranslation()

  const columns = useMemo(() => generateColumns({t, checklistsPrefix}), [t, checklistsPrefix])

  return (
    <DataTable<ChecklistTemplate>
      columns={columns}
      data={data}
      loading={isLoading}
      emptyMessage={emptyMessage}
      data-test-id={'checklist-templates-table'}
    />
  )
}

const generateColumns = ({
  t,
  checklistsPrefix
}: {
  t: TFunction
  checklistsPrefix: string
}): Column<ChecklistTemplate>[] => [
  {
    key: 'name',
    label: t(`${checklistsPrefix}.label.checklistName`)
  },
  {
    key: 'description',
    label: t(`${checklistsPrefix}.label.description`)
  },
  {
    key: 'type',
    label: t(`${checklistsPrefix}.label.type`),
    customTemplate: ({type}) =>
      type ? t(`${checklistsPrefix}.checklist.templateType.${type}`) : '-'
  },
  {
    key: 'category',
    label: t(`${checklistsPrefix}.label.category`),
    customTemplate: ({category}) =>
      category ? t(`${checklistsPrefix}.checklist.templateCategory.${category}`) : '-'
  },
  {
    key: 'createdAt',
    label: t(`${checklistsPrefix}.label.addedToTheSystem`),
    customTemplate: ({createdAt}) => (createdAt ? dateFormatter(moment(createdAt)) : '-')
  }
]
