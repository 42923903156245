import {BackToLink as UiKitBackToLink} from '@hconnect/uikit/src/lib2'
import {useNavigate} from 'react-router'

type Props = {
  target: string
  targetName: string
}

export const BackToLink = (props: Props) => {
  const navigate = useNavigate()

  return <UiKitBackToLink {...props} navigate={navigate} />
}
